<template>
	<div class="insure-query">
		<QueryHeader @onLogin="handleLogin"></QueryHeader>
		<div class="container">
			<div class="left">
				<QueryNavMenu :menuList="menuList" @categoryChange="handleCategoryChange"></QueryNavMenu>
			</div>
			<div class="right">
				<QueryContent :categoryId="categoryId"></QueryContent>
			</div>
		</div>
	</div>
</template>

<script>
	import QueryHeader from "./components/header.vue"
	import QueryNavMenu from "./components/navMenu.vue"
	import QueryContent from "./components/queryContent.vue"
	export default {
		components: {
			QueryHeader,
			QueryNavMenu,
			QueryContent
		},
		data() {
			return {
				menuList: [],
				categoryId: ''
			}
		},
		created() {
			this.getMenuList()
		},
		methods: {
			handleLogin() {
				this.getMenuList()
			},
			handleCategoryChange(id) {
				this.categoryId = id
			},
			getMenuList() {
				this.$http.fetchGet('/api/admin/category/tree').then(res => {
					this.menuList = res.data.data
				})
			},
		}
	}
</script>

<style lang="scss">
	@import '~@/assets/css/insure-query.scss';

	body {
		min-width: 1500px;
	}
</style>
